<template>
    <div class="tips  bbox">
      <p class="title f18 fw700 textc mt30">
      <!-- {{ $t("regInstruct.RegistrationForNewUsers") }} -->
      Privacy Policy
    </p>
    <p class="title f18 fw700 textc pb30">
      <!-- {{ $t("regInstruct.RegistrationForNewUsers") }} -->
      个人信息保护政策
    </p>
    <div>
      <p class="texti2">
        爱科来国际贸易（上海）有限公司（“爱科来”、“我们”、“本公司”）将依法妥善处理您的个人信息。请在向本公司提交个人信息之前，阅读、了解本《个人信息保护政策》（下文简称“本政策”）。
      </p>
      <p class="texti2">
        本公司保留不时更新或修改本政策的权利。如果我们的隐私政策变更，我们会将最新版隐私政策发布在这里。如果我们对隐私政策做出了重大变更，我们还可能会通过不同渠道向您发送变更通知。
      </p>
      <p class="f14 fw700 mt30">我们制定本政策的目的在于帮助您了解以下内容：</p>
       <!-- <p class="texti2">
       本隐私声明最近更新日期： 2021年4月2日
      </p> -->
      <p class="f14 mt30">1.收集的个人数据的内容及其收集方法</p>
      <p class="f14 mt30">2.个人数据的使用目的</p>
      <p class="f14 mt30">3.个人数据的保管</p>
      <p class="f14 mt30">4.对个人数据的共享</p>
      <p class="f14 mt30">5.个人数据保护措施</p>
      <p class="f14 mt30">6.个人作为数据主体拥有的权利</p>
      <p class="f14 mt30">7.关于Cookie的使用</p>
      <p class="f14 mt30">8.本公司的联系方式</p>
       <p class="title f14 fw700 mt30">
      １．个人信息的内容及收集方法
      </p>
      <p>
       “个人信息”是本公司为业务目的所收集的姓名、年龄、出生年月日、性别、住址、电话号码、邮件地址、工作单位等有关个人的信息，可以通过其中的一个或多个信息的组合来识别特定的个人。
      </p>
      <p>
      本公司仅会出于本声明所述目的收集和使用您的个人信息，我们可能收集的个人信息包括：
      </p>
      <p>
        您的姓名、公司名称、邮箱、联系电话等。
      </p>
      <p>
      	收集个人信息的时候，我们会告知使用目的以及问询窗口等。
      </p>

      <p class="title f14 fw700 mt30">
      2．个人数据及其使用目的
      </p>
      <p class="texti2">
       本公司主要持有的个人数据及其使用目的如下。
      </p>
      <div>
      <table style="margin-bottom:50px;" align="left" border="1" cellpadding="6"  cellspacing="0" >
        <tbody align="center">
          <el-row>
              <th style="border-top:none;width:25vw;border-left:none;">收集场景</th>
		  			  <th style="border-top:none;width:30vw;">主要持有的个人数据</th>
		  			  <th style="border-top:none;text-align:left;width:40vw;">主要使用目的</th>
          </el-row>
          <el-row>
              <th style="border-left:none;width:25vw;">注册账户</th>
		  			  <th style="width:30vw;">姓名、密码、邮箱、联系电话</th>
		  			  <th style="text-align:left;width:40vw;">
                <p>·进行登录代理商权限管理</p>
                <p>·售后培训通知</p>
                <p>·培训前和/或培训后考试成绩记录、收集和分析（每次培训完成后）</p>
                <p>·培训考核合格证书制作、发放（每次培训完成后）</p>
                <p>·与您沟通，回复咨询</p>
                </th>
          </el-row>
          <el-row>
              <th style="border-left:none;width:25vw;">参加培训</th>
		  			  <th style="width:30vw;">姓名、密码、邮箱、联系电话、培训成绩</th>
		  			  <th style="text-align:left;width:40vw;">
                ·培训成绩记录、收集和分析
                </th>
          </el-row>
        </tbody>
      </table>
      </div>
      <p class="texti2">
      ※培训成绩在其他信息删除后可能会予以保留，但会进行匿名化处理。
      </p>
      <p class="title f14 fw700 mt30">
      3．个人信息的保管
      </p>
      <p>
       我们在中华人民共和国境内运营中收集和处理的个人信息，将存储在中华人民共和国境内。我们将会在达成本政策所述目的所需的期限内保留您的个人信息，除非按照法律要求或许可需要延长保留期或受到法律的允许。
      </p>
      <p class="title f14 fw700 mt30">
      4．个人数据的共享
      </p>
      <p>
       （1）本网站由本公司委托安徽云蓝信息科技有限公司设计、开发并维护。基于其履行网站维护需要，将会接触到您的个人信息。我们要求其履行保密义务。除此之外，如果我们委托其处理个人信息的，我们会与其签署委托处理协议，要求其按照协议的约定、本政策以及适用的数据保护法律的要求来处理个人信息。
      </p>
      <p>
       （2） 本网站收集的信息将存储在阿里云服务器中。阿里云作为云服务提供商，未经本公司许可或者执行明确的法律法规要求外，不会对您的个人数据进行任何非授权的使用或披露。详细了解阿里云的《隐私权政策》（云安全访问服务隐私权政策 (aliyun.com)）。如果我们委托其处理个人信息的，我们会与其签署委托处理协议，要求其按照协议的约定、本政策以及适用的数据保护法律的要求来处理个人信息。
      </p>
      <p>
       （3）我们可能会根据法律法规规定、诉讼、争议解决的必要，或按行政、司法机关依法提出的要求，以及其他法定义务履行的必需，共享您的个人信息。
      </p>
      <p>
       （4）除以上情形外，除非依法取得您的同意，我们不会公开披露或处理您的个人信息。
      </p>
     <p class="title f14 fw700 mt30">
      5. 个人数据保护措施
      </p>
      <p>
       我们采用适当的物理、管理和技术保障措施来保护您的个人信息，通过妥善管理个人信息，致力于防止个人信息丢失、破坏、篡改、泄露以及非法获取个人信息。我们会尽力保护您的个人信息，但是请注意任何安全措施都无法做到无懈可击。
      </p>    
      <p class="title f14 fw700 mt30">
      6. 个人作为数据主体拥有的权利
      </p>
      <p>
       您应确保提交的所有个人信息都准确无误。本公司会尽力维护个人信息的准确和完整，并及时更新这些数据。
      </p>
      <p>
       当适用的法律要求的情况下，您可能（1）有权查阅、复制我们持有的关于您的特定的个人信息；（2）要求我们更新或更正您的不准确的个人信息；（3）拒绝或限制我们使用您的个人信息；（4）要求我们删除您的个人信息；（5）要求我们对个人信息处理规则进行解释说明。
      </p>
      <p>
       当适用的法律要求的情况下，当本公司基于您的同意处理您的个人信息时，您还有权随时撤销您的同意。但撤销同意不会影响撤销前我们基于您的同意处理您个人信息的合法性及效力，也不影响我们基于其他适当的正当性基础处理您的个人信息。
      </p>
      <p>
       如果您想行使相关的权利，可以通过第8条所列联系方式反馈给我们。为保障安全，您可能需要提供书面请求。如果我们有合理依据认为这些请求存在欺骗性、无法实行或损害他人隐私权，我们则会拒绝处理请求。
      </p>
      <p class="title f14 fw700 mt30">
     7. 关于Cookie的使用
      </p>
      <p>
       为确保网站正常运转，我们有时会在计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 是一种网络服务器存储在计算机或移动设备上的纯文本文件。Cookie 的内容只能由创建它的服务器检索或读取。每个 Cookie 对您的网络浏览器或移动应用程序都是唯一的。
      </p>
      <p>
       本公司启用Cookie的目的在于改善用户体验。借助于 Cookie，网站能够记住用户的单次访问（使用会话 Cookie）或多次访问（使用永久 Cookie）。借助于 Cookie，网站能够保存设置，例如计算机或移动设备的语言、字体大小和其他浏览偏好。这意味着，用户无需在每次访问时重新配置用户偏好设置。本公司不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。
      </p>
      <p class="title f14 fw700 mt30">
     8. 批评建议等联络方式
      </p>
      <p>
        如果您对公司处理个人信息有任何批评或建议，请联系：      </p>
      <p>
      	地址    ：上海市浦东新区金科路2889弄3号长泰大厦C座202室
      </p>
      <p>
       电话    ：400-8203867/021-5081-2554
      </p>
      <p>
      	联系人  ：售后服务部
      </p>
      <p>
      	邮箱    ：<span style="color: #4e4eeb;">service_site@arkray.co.jp</span> 
      </p> 
      <p style="text-align:right" class="mt30 fw700">
       爱科来国际贸易（上海）有限公司
      </p>
      <p style="text-align:right" class="fw700">
       2021年12月13日
      </p>
    </div>
    <!-- <p class="title f14 fw700 textc">{{ $t('advert.Advertising') }}</p>
    <div class="f12">
      <p class="texti2">
        {{ $t('advert.TopInstructions') }}
      </p>
      <p class="f12 fw700 mt10">{{ $t('advert.AccountRegistrationUse') }}</p>
      <p>
        {{ $t('advert.TheTermsOfOneFirst') }}
      </p>
      <p>
        {{ $t('advert.TheTermsOfOneSecond') }}
      </p>
      <p>
        {{ $t('advert.TheTermsOfOneThird') }}
      </p>
      <p>
         {{ $t('advert.TheTermsOfOneFourth') }}
      </p>
      <p>
        {{ $t('advert.TheTermsOfOneFifth') }}
      </p>
      <p>
        {{ $t('advert.TheTermsOfOneSixth') }}
      </p>
         <p class="f12 fw700 mt10">{{ $t('advert.AccountRegistrationUseTwo') }}</p>
      <p>
        {{ $t('advert.TheTermsOfOneFirst') }}
      </p>
      <p>
        {{ $t('advert.TheTermsOfOneSecond') }}
      </p>
      <p>
        {{ $t('advert.TheTermsOfOneThird') }}
      </p>
      <p>
        {{ $t('advert.TheTermsOfOneFourth') }}
      </p>
      <p>
        {{ $t('advert.TheTermsOfOneFifth') }}
      </p>
      <p>
        {{ $t('advert.TheTermsOfOneSixth') }}
      </p>
    </div> -->
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.tips {
  background-color: #fff;
  padding: 20px 13px;
  
}
</style>